//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #0000FF;
$primary-active: #0000E0;
$primary-light: #99B3FF;
$primary-inverse: #FFFFFF;

// Success
$success: #00F6AD;
$success-active: #00D68F;
$success-light: #F1FAFF;
$success-inverse: #FFFFFF;

// Info
$info: #5710B2;
$info-active: #41058E;
$info-light: #F6F1FC;
$info-inverse: #FFFFFF;

// Danger
$danger: #F1416C;
$danger-active: #D9214E;
$danger-light: #FFEFF3;
$danger-inverse: #FFFFFF;

// Warning
$warning: #FFC700;
$warning-active: #F1BC00;
$warning-light: #FFF8DD;
$warning-inverse: #FFFFFF;

// Border Radiues
$border-radius-sm: .3rem;
$border-radius: .65rem;
$border-radius-lg: .85rem;
$border-radius-xl: 1.25rem;

// Card Box Shadow
$card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);